.b-btn {
  background-color: var(--color-white);
  color: var(--color-text-primary);
  padding: 2rem;
  border: 0;
  border-radius: 2.5rem;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-family-primary);
  cursor: pointer;

  &--sec {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }

  &--third {
    background-color: var(--color-primary);
    border: 4px solid var(--color-white);
    color: var(--color-white);
  }
}
