.c-sound-control-menu {
  width: 100%;
  background-color: var(--color-primary);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 7vh;
  z-index: 1;

  &--no-sounds {
    .c-sound-control-menu__grid {
      left: 100%;
    }

    .c-sound-control-menu__grid--sec {
      left: 0%

    }
  }

  &__grid {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 12rem auto 12rem;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;

    transition: 0.5s left cubic-bezier(1, -0.3, 0.3, 1);

    &--sec {
      grid-template-columns: 100%;
      left: -100%;
    }
  }


}
