.g-primereact-dialog {
  &.p-dialog {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    max-height: 100%;
    z-index: 10;
  }

  &.p-dialog {
    max-width: 100%;
    border-radius: 0;
    padding: 2rem;
  }


}

.p-dialog-header-icon {
  position: relative;
  font-size: 2.5rem;
  color: var(--color-text-primary);
  background-color: transparent;
  border: 0;

  .pi-times:before {
    content: $icon-close-modal;
    font-family: noisse-icons;

  }
}

.p-dialog-mask {
  height: calc(100% - 7vh);
}

.p-dialog {
  background-color: var(--color-white);
  padding: 3rem;
  max-width: calc(100% - 50px);
  color: var(--color-text-primary);
  border-radius: 1.5rem;
}

.p-dialog-title {
  font-weight: bold;
}

.p-dialog-mask {
  background-color: var(--color-primary-30);
}

.p-button {
  padding: 2rem;
  border: 0;
  border-radius: 2.5rem;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-family-primary);
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.p-dialog-footer {
  display: flex;
}

.p-confirm-dialog-reject {
  margin-right: 1rem;
  background-color: var(--color-white);
  color: var(--color-secondary);
  border: 4px solid var(--color-secondary);
}

.p-dialog-content {
  padding: 2rem 0;
}
