body {
  color: var(--color-white);
  font-family: var(--font-family-primary);
  background-image: linear-gradient(130deg, var(--color-background) 0%, var(--color-background-secondary) 100%);
  font-size: 2rem;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  margin: 0;
}
