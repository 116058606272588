.b-badge{
  width: 7rem;
  height: 7rem;
  background-color: var(--color-white);
  border-radius: 100%;
  padding: 1.25rem;
  &--facebook{
    background-color: var(--color-facebook);
  }
}
