.g-primereact-calendar {

  &--timer {
    &.p-calendar {
      position: relative;
      .p-inputtext {
        border: 0;
        border-radius: 2.5rem;
        padding: 1rem;
        text-align: center;
      }
       .p-datepicker{
         background-color: var(--color-white);
         border-radius: 2.5rem;
         color: var(--color-text-primary);
         padding: 1.5rem;
         font-family: var(--font-family-primary);
         transform: translateY(-.5rem);
       }

      .p-minute-picker > span, .p-hour-picker > span, .p-second-picker > span{
        margin: 1rem 0;
      }
      .p-link{
        background-color: var(--color-background);
        border-radius: 100%;
        border: none;
        padding: 2rem;
        color: var(--color-active);
      }
      &:before {
        content: $icon-timer;
        font-family: noisse-icons;
        position: absolute;
        color: var(--color-primary);
        top: 50%;
        left: 1.5rem;
        transform: translateY(-50%);
        opacity: .5;
      }
      &:after {
        content: $icon-timer;
        font-family: noisse-icons;
        position: absolute;
        color: var(--color-primary);
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        opacity: .5;
      }
    }

  }
}
