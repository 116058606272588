.g-primereact-slider {
  &.p-slider {
    width: 100%;
    height: 6px;
    border-radius: 10px;
    background: var(--color-white);
    transition: .25s background-color ease-in-out;

  }

  &.p-slider-horizontal .p-slider-handle {
    z-index: 0;
    top: -8px;
    width: 2.5rem;
    height: 2.5rem;
    transform: translateX(-50%);
    border: 2px solid var(--color-white);
    border-radius: 100%;
    background-color: var(--color-primary);
    transition: .25s background-color ease-in-out;
  }

  &--sec {
    &.p-slider {
      background: var(--color-background-secondary);
    }
  }

  &--active {
    &.p-slider {
      background: var(--color-secondary);
    }

    &.p-slider-horizontal .p-slider-handle {
      background-color: var(--color-secondary);
    }
  }
}
