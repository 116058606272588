.g-primereact-toast {
  .p-toast-message {
    position: fixed;
    top: 2rem;
    right: 2rem;
    width: auto;
    max-width: 50rem /* 240/8 */
  ;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 2rem 5rem 2rem 2rem;
    border: 4px solid var(--color-white);
    border-radius: 2.5rem;
    z-index: 2;
    &.p-toast-message-error{
      background-color: var(--color-error);
    }
    &.p-toast-message-success{
      background-color: var(--color-success);
    }
    &.p-toast-message-info{
      background-color: var(--color-info);
    }
    &.p-toast-message-warning{
      background-color: var(--color-warning);
    }
  }

  .p-toast-summary {
    font-weight: bold;
  }

  .p-toast-icon-close {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;

    .p-toast-icon-close-icon:before {
      content: $icon-close-modal;
      font-family: noisse-icons;
      color: var(--color-white);

    }
  }

  .p-toast-message-content {
    display: flex;
  }

  .p-toast-message-icon {
    padding-right: 2rem;
    display: flex;
    align-items: center;
  }

}
