.c-sound-gallery {
  position: relative;
  width: 100%;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    cursor: pointer;
  }

  &__balls {
    width: 100%;
  }

  &__ball {
    width: 1rem;
    height: 1rem;
    //border: 1px solid var(--color-white);
    opacity: .9;
    border-radius: 100%;
    display: inline-block;
  }
}
