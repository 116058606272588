.c-menu {
  display: grid;
  grid-template-columns: 120px auto 120px;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0;

  &__link {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-white);
    display: flex;

  }
  &__logo{
    font-size: 315px;
    font-family: Cervanttis;
    fill: #fff;

  }
}


