$icomoon-font-family: "noisse-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-storm1: "\e930";
$icon-timer: "\e92f";
$icon-chest-of-drawers: "\e92c";
$icon-sesame: "\e92d";
$icon-box: "\e92e";
$icon-potato-chips: "\e92b";
$icon-kiss: "\e92a";
$icon-bloque-de-oficinas: "\e929";
$icon-estrella: "\e928";
$icon-frog: "\e90c";
$icon-coffee-cup: "\e90d";
$icon-tent: "\e90e";
$icon-bonfire: "\e90f";
$icon-trees: "\e910";
$icon-wind: "\e911";
$icon-campana-de-viento: "\e912";
$icon-gong: "\e913";
$icon-sun: "\e914";
$icon-close-modal: "\e90b";
$icon-close: "\e90a";
$icon-shuffle: "\e909";
$icon-planet-earth: "\e906";
$icon-home: "\e907";
$icon-gears: "\e908";
$icon-heart: "\e905";
$icon-fan: "\e900";
$icon-air-conditioning: "\e901";
$icon-car-engine: "\e902";
$icon-waves1: "\e903";
$icon-waves: "\e904";
$icon-rain-1: "\e915";
$icon-shower: "\e916";
$icon-train: "\e917";
$icon-bubble: "\e918";
$icon-river: "\e919";
$icon-waterfall: "\e91a";
$icon-waves2: "\e91b";
$icon-sound-frecuency: "\e91c";
$icon-sound-waves: "\e91d";
$icon-rainy-day: "\e91e";
$icon-storm: "\e91f";
$icon-cancel: "\e920";
$icon-pause: "\e921";
$icon-play: "\e922";
$icon-profile-user: "\e923";
$icon-stop: "\e924";
$icon-volume: "\e925";
$icon-blender: "\e926";
$icon-hairdryer: "\e927";

