@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?csbhro') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?csbhro') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?csbhro##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-storm1 {
  &:before {
    content: $icon-storm1; 
  }
}
.icon-timer {
  &:before {
    content: $icon-timer; 
  }
}
.icon-chest-of-drawers {
  &:before {
    content: $icon-chest-of-drawers; 
  }
}
.icon-sesame {
  &:before {
    content: $icon-sesame; 
  }
}
.icon-box {
  &:before {
    content: $icon-box; 
  }
}
.icon-potato-chips {
  &:before {
    content: $icon-potato-chips; 
  }
}
.icon-kiss {
  &:before {
    content: $icon-kiss; 
  }
}
.icon-bloque-de-oficinas {
  &:before {
    content: $icon-bloque-de-oficinas; 
  }
}
.icon-estrella {
  &:before {
    content: $icon-estrella; 
  }
}
.icon-frog {
  &:before {
    content: $icon-frog; 
  }
}
.icon-coffee-cup {
  &:before {
    content: $icon-coffee-cup; 
  }
}
.icon-tent {
  &:before {
    content: $icon-tent; 
  }
}
.icon-bonfire {
  &:before {
    content: $icon-bonfire; 
  }
}
.icon-trees {
  &:before {
    content: $icon-trees; 
  }
}
.icon-wind {
  &:before {
    content: $icon-wind; 
  }
}
.icon-campana-de-viento {
  &:before {
    content: $icon-campana-de-viento; 
  }
}
.icon-gong {
  &:before {
    content: $icon-gong; 
  }
}
.icon-sun {
  &:before {
    content: $icon-sun; 
  }
}
.icon-close-modal {
  &:before {
    content: $icon-close-modal; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle; 
  }
}
.icon-planet-earth {
  &:before {
    content: $icon-planet-earth; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-gears {
  &:before {
    content: $icon-gears; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-fan {
  &:before {
    content: $icon-fan; 
  }
}
.icon-air-conditioning {
  &:before {
    content: $icon-air-conditioning; 
  }
}
.icon-car-engine {
  &:before {
    content: $icon-car-engine; 
  }
}
.icon-waves1 {
  &:before {
    content: $icon-waves1; 
  }
}
.icon-waves {
  &:before {
    content: $icon-waves; 
  }
}
.icon-rain-1 {
  &:before {
    content: $icon-rain-1; 
  }
}
.icon-shower {
  &:before {
    content: $icon-shower; 
  }
}
.icon-train {
  &:before {
    content: $icon-train; 
  }
}
.icon-bubble {
  &:before {
    content: $icon-bubble; 
  }
}
.icon-river {
  &:before {
    content: $icon-river; 
  }
}
.icon-waterfall {
  &:before {
    content: $icon-waterfall; 
  }
}
.icon-waves2 {
  &:before {
    content: $icon-waves2; 
  }
}
.icon-sound-frecuency {
  &:before {
    content: $icon-sound-frecuency; 
  }
}
.icon-sound-waves {
  &:before {
    content: $icon-sound-waves; 
  }
}
.icon-rainy-day {
  &:before {
    content: $icon-rainy-day; 
  }
}
.icon-storm {
  &:before {
    content: $icon-storm; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-profile-user {
  &:before {
    content: $icon-profile-user; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-volume {
  &:before {
    content: $icon-volume; 
  }
}
.icon-blender {
  &:before {
    content: $icon-blender; 
  }
}
.icon-hairdryer {
  &:before {
    content: $icon-hairdryer; 
  }
}

