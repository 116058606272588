:root{
  --color-primary: #7ae2ff; // #6ad5eb; // #86D7E8
  --color-primary-30: rgb(106 213 235 / 30%);
  --color-secondary: #05556b; //#CD97FD
  --color-third: #FBD87F;
  --color-white: #FFFFFF;
  --color-text-primary: #05556b;

  --color-facebook: #1778F2;

  --color-background: var(--color-primary);
  --color-background-secondary: #cef6ff;

  --color-warning: #FBD87F;
  --color-error: #FF715B;
  --color-success: #3AB795;
  --color-info: #2D7DD2;

  --color-category-Popular: #FBD87F;
  --color-category-Nature: #3AB795;
  --color-category-Home: #941C2F;
  --color-category-City: #05556b;
  --color-category-Motor: #03191E;
  --color-category-Noise: #8B8982;
  --color-category-ASMR: #AF125A;

}
