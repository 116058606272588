.b-text {
  color: var(--color-white);
  font-family: var(--font-family-primary);
  font-size: 2rem;

  &--important {
    color: var(--color-secondary);
    font-weight: bold;
  }
}
