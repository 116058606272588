.b-icon {
  color: var(--color-white);
  font-size: 10rem;
  position: relative;
  display: inline-flex;
  transition: .25s color ease-in-out;
  &--active {
    color: var(--color-secondary);
  }

  &--primary {
    color: var(--color-text-primary);
  }

  &--s {
    font-size: 4rem;
  }

  &--xs {
    font-size: 3rem;
  }

  &--xxs {
    font-size: 2rem;
  }

  &__badge {
    font-size: 3rem;
    margin: auto 0.5rem;
    font-family: var(--font-family-primary);
    line-height: 1;
  }

}
