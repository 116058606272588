.c-sound-list {
  &__item {
    color: var(--color-text-primary);
    display: grid;
    grid-template-columns: auto 60% auto;
    //grid-template-columns: auto 20% 50% auto;
    align-items: center;
    margin: 2rem 0;
  }

  &__remove {
    font-size: 2.5rem;
  }
}
