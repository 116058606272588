.c-filter-sounds {
  position: sticky;
  top: 0;
  left: 0;
  padding: 1.5rem 0;
  z-index: 1;


  &__border {
    &:before {
      content: '';
      height: 1rem;
      display: inline-block;
      background-color: var(--color-white);
      width: 1rem;
      border-radius: 100%;
    }
  }

  &__icon {
    margin-left: 1rem;

    &:before {
      font-size: 2rem;
      color: var(--color-white);
    }
  }
}

.swiper-slide {
  width: auto !important;
}
