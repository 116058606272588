.c-sound-comb {
  display: flex;
  border: 2px solid var(--color-white);
  border-radius: 2.5rem;
  cursor: pointer;
  &__sound {
    padding: 10px 0;
    border-right: 2px solid var(--color-white);
    width: 100%;
    text-align: center;
    border-radius: 2.5rem;
    overflow: hidden;

    &--last {
      border-right: 0;
    }
  }
}
