.b-bubble {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 1.2rem;
  border-radius: 2.5rem;
  width: auto !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 5.5rem;
  justify-content: center;
  border: 3px solid transparent;
  height: 4.5rem !important;
  box-sizing: border-box;
  font-family: var(--font-family-primary);
  outline: none;

  &--active {
    border: 3px solid var(--color-white);

    color: var(--color-white);

    .b-bubble__icon:before {
      color: var(--color-white);

    }

  }

  &--min-width-big {
    min-width: 8.5rem;
  }


  &--active {
    background-color: var(--color-secondary);
    height:  4.5rem;
    position: relative;
    border: 3px solid var(--color-white);

  }

  //&--border-popular {
  //  border-width: 3px;
  //  border-style: solid;
  //  border-color: var(--color-third);
  //}



}
